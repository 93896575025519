<template>
  <div>
    <order-list />
  </div>
</template>

<script>
import OrderList from "@/components/OrderList";
export default {
  components: { OrderList },
};
</script>

<style>
</style>